<template lang="pug">
.paymentCard
  .paymentCard-title {{title}}
  .paymentCard-content: slot
</template>

<script>

export default {
  props: ['title']
}
</script>

<style lang="scss">
.paymentCard {
  width: 240px;
  height: 75px;
  border-radius: 18px;
  background: white;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.paymentCard-title  {
  font-size: 12px;
  line-height: 15px;
  color: $grey-4;
}
.paymentCard-content  {
  font-size: 14px;
  line-height: 130%;
  color: $grey-2;
  display: flex;
  gap: 12px
}



</style>
