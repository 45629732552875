<template lang="pug">
.paymentPage.layout
  h4.h1.paymentPage-title История платежей
  .paymentPage-header
    PaymentCard(title="Текущий тариф")
      span.weight-medium 
        span LabStory 
        span.weight-medium.text-primary Mini
    PaymentCard(title="Следующий платеж")
      span 23 ноября 2021
      span.weight-medium.q-ml-auto 696₽
    PaymentCard(title="Карта для платежей")
      CardIcon
      span **** 1419
      span.text-grey-4 08/24
  PaymentList.paymentPage-paymentList
</template>

<script>
import CardIcon from '@/components/CardIcon.vue'
import PaymentList from './PaymentList.vue'
import PaymentCard from './PaymentCard.vue'

export default {
  components: {
    CardIcon, PaymentList, PaymentCard
  }
}
</script>

<style lang="scss">
.paymentPage{
  display: flex;
  flex-direction: column;
  gap: 30px

}
.paymentPage-header{
  display: flex;
  gap: 12px;
  @include media-down($breakpoint-md){
    flex-direction: column;
  }
}
</style>
