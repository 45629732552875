<template lang="pug">
.paymentList
  PaymentItem._header
    span Какой тариф оплачен
    span Стоимость
    span На какой срок
    span Дата оплаты
  PaymentItem
  PaymentItem
  PaymentItem
  PaymentItem
</template>

<script>
import PaymentItem from './PaymentItem.vue'
export default {
  components: {
    PaymentItem
  }
}
</script>

<style lang="scss">
.paymentList{
  max-width: 1040px;
  display: flex;
  flex-direction: column;
  // gap: 7px
  @include media-down($breakpoint-md){
    border-radius: 25px;
    overflow: hidden;
    box-shadow: 0px 4px 15px rgba(22, 20, 44, 0.06);
  }
}
</style>
